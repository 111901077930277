.embla {
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: 90vh;
    
    display: flex;
    
  }

  .embla-thumbs {
    --thumbs-slide-spacing: 0.8rem;
    --thumbs-slide-height: 17vh;
    padding-right: var(--thumbs-slide-spacing);
    width: 20%;
   
  }
  .embla-thumbs img{ background-color: #fdf6ee;}
  .embla-thumbs *{user-select: none;}
  @media(max-width:1024px){
    .embla {
      --slide-spacing: 2rem;
      --slide-size: 100%;
      --slide-height: 60vh;
      
      display: flex;
      
    }
    .embla-thumbs {
      --thumbs-slide-spacing: 1.8rem;
      --thumbs-slide-height: 17rem;
      padding-right: var(--thumbs-slide-spacing);
      width: 20%;
     
    }
  }
  @media(max-width:750px){
    .embla {
      --slide-spacing: 2rem;
      --slide-size: 100%;
      --slide-height: 52vh;
      
      display: flex;
      
    }
    .embla-thumbs {
      --thumbs-slide-spacing: 1rem;
      --thumbs-slide-height: 15vh;
      padding-right: var(--thumbs-slide-spacing);
      width: 25%;
     
    }
  }
  .embla__viewport {
    overflow: hidden;
    width: 80%;
  }
  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 90vh;
    background-color: #fdf6ee;
   
  }
  .embla__slide .ant-image{width: 100% !important; height: 100% !important; overflow: hidden !important; display: flex; align-items: center; justify-content: center;  }
  .embla__slide__img {
    display: block;
    height: auto;
    width: 100%;
    object-fit: cover;
  }
  .embla__slide__number {
    width: 4.6rem;
    height: 4.6rem;
    z-index: 1;
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    border-radius: 50%;
    background-color: rgba(var(--background-site-rgb-value), 0.85);
    line-height: 4.6rem;
    font-weight: 900;
    text-align: center;
    pointer-events: none;
  }
  .embla__slide__number > span {
    color: var(--brand-primary);
    background-image: linear-gradient(
      45deg,
      var(--brand-primary),
      var(--brand-secondary)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.6rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
 
  .embla-thumbs__viewport {
    overflow: hidden;
    background-color: #fdf6ee;
  }
  .embla-thumbs__container {
    display: flex;
    flex-direction: column;
    height: 90vh;
    margin-left: calc(var(--thumbs-slide-spacing) * -1);
  }
  .embla-thumbs__slide {
    flex: 0 0 28%;
    min-width: 0;
    padding-bottom: var(--thumbs-slide-spacing);
    position: relative;
    }
  @media (min-width: 576px) {
    .embla-thumbs__slide {
      flex: 0 0 18%;
    }
  }
  .embla-thumbs__slide__button {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: block;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    
    width: 100%;
    opacity: 1;
    transition: opacity 0.2s;
  }
  .embla-thumbs__slide--selected .embla-thumbs__slide__button {
    opacity: 1;
  }
  .embla-thumbs__slide__img {
    display: block;
    height: auto;
    width: 100%;
    object-fit: cover;
    height: 10vw;
  }
  .embla__slide video{height: 100%;}
  .embla-thumbs__slide__number {
    width: 3rem;
    height: 3rem;
    z-index: 1;
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    border-radius: 50%;
    /* background-color: rgba(var(--background-site-rgb-value), 0.85); */
    line-height: 3rem;
    font-weight: 900;
    text-align: center;
    pointer-events: none;
  }
  .embla-thumbs__slide__number > span {
    color: var(--brand-primary);
    background-image: linear-gradient(
      45deg,
      var(--brand-primary),
      var(--brand-secondary)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.4rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media(max-width:1024px){
    .embla-thumbs__container {
      height: 60vh;
     }
    .embla__slide {
      height: 60vh;
      }
      .embla-thumbs__slide__img {
        height: 17vw;
      }
  }
  @media(max-width:767px){
    .embla-thumbs__container {
      height: 46vh;
     }
    .embla__slide {
      height: 46vh;
      }
      .embla-thumbs__slide__img {
        height: 25vw;
      }
      .embla-thumbs{width: 20%;}
  }
  