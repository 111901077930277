.lazy-image-container {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
.lazy-image-container span{width: 100%; display: flex !important; justify-content: center; align-items: center;}
  .lazy-image-container .loaded::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(#ffffff50, transparent);
    backdrop-filter: blur(10px);
  }
  .lazy-load-image-background{width: 100%;}
 .card_img .lazy-load-image-background.blur.lazy-load-image-loaded > img{width: 100%;}
 